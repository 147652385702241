// Core Modules
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
// Models
import { TAudienceCriteria, TCriteriaChildren, TCriteriaQuery, TInvalidCriteriasGroupedModel, TSegmentModel } from '../models/criteria.model';
// Enums
import { ChannelAvailabilitySelectOptionsEnum, CriteriaIdEnum, CriteriaTypeEnum, TemplateKeys } from '../enums/audience.enum';
import { TInvalidAudienceResponse, TInvalidCriteriaList } from '../models/audience-config.model';
import * as lodash from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SegmentQueryService {
  // Public Members
  public readonly defaultChannelAvailabilityForLkp: string[] = [ChannelAvailabilitySelectOptionsEnum.EMAIL, ChannelAvailabilitySelectOptionsEnum.PHONE];
  public readonly descTypesCriteria: string[] = [
    CriteriaIdEnum.DIAGNOSIS_CODE,
    CriteriaIdEnum.MSDRG_CODE,
    CriteriaIdEnum.PROCEDURE_CODE,
    CriteriaIdEnum.CPT_CODE,
    CriteriaIdEnum.CHUI,
    CriteriaIdEnum.PDI,
    CriteriaIdEnum.FACILITY_CODE,
    CriteriaIdEnum.PERCEPTUAL_PROFILE_CODE,
    CriteriaIdEnum.RECENCY_FREQUENCY_CODE,
    CriteriaIdEnum.MARKET_AREA,
    CriteriaIdEnum.FINANCIAL_CLASS,
    CriteriaIdEnum.ADMIT_SOURCE,
    CriteriaIdEnum.ADMIT_TYPE,
    CriteriaIdEnum.PATIENT_TYPE,
    CriteriaIdEnum.EDUCATION_LEVEL,
    CriteriaIdEnum.DISCHARGE_DISPOSITION,
    CriteriaIdEnum.NET_WORTH,
    CriteriaIdEnum.HOUSEHOLD_INTERESTS,
    CriteriaIdEnum.HOME_OWNER,
    CriteriaIdEnum.OCCUPATION,
    CriteriaIdEnum.GENDER,
    CriteriaIdEnum.LANGUAGE,
    CriteriaIdEnum.MARITAL_STATUS,
    CriteriaIdEnum.RACE,
    CriteriaIdEnum.ENCOUNTER_TYPE,
    CriteriaIdEnum.ENCOUNTER_PAYOR_CATEGORY,
    CriteriaIdEnum.SERVICE_CATEGORY,
    CriteriaIdEnum.SERVICE_SUB_CATEGORY,
    CriteriaIdEnum.HOUSEHOLD_AGE_BANDS,
    CriteriaIdEnum.PERSON_PAYOR_CATEGORY,
    CriteriaIdEnum.POSTAL_MOVED_TO,
    CriteriaIdEnum.POSTAL_MOVED_FROM,
    CriteriaIdEnum.MOVE_FROM_MARKET_AREA,
    CriteriaIdEnum.MOVE_TO_MARKET_AREA,
    CriteriaIdEnum.EVENT_TYPE,
    CriteriaIdEnum.EVENT_CAMPAIGN,
    CriteriaIdEnum.EVENT_CHANNEL,
    CriteriaIdEnum.CALL_TYPE,
    CriteriaIdEnum.PROVIDER_REFERRAL_OUTCOME,
    CriteriaIdEnum.SERVICE_REFERRAL_OUTCOME,
    CriteriaIdEnum.EVENT_REGISTRATION_OUTCOME,
    CriteriaIdEnum.NICHE_CODE,
    CriteriaIdEnum.HG_CUST_SERVICE_CATEGORY,
    CriteriaIdEnum.HG_CUST_SERVICE_SUB_CATEGORY,
    CriteriaIdEnum.PATIENT_PAYOR_NAME,
    CriteriaIdEnum.EVENT_HRA_LOCATION,
    CriteriaIdEnum.ACTIVITY_DIVISION_CODE,
    CriteriaIdEnum.ENCOUNTER_DIVISION_CODE,
    CriteriaIdEnum.SERVICE_LINE,
    CriteriaIdEnum.SUB_SERVICE_LINE,
    CriteriaIdEnum.SUB_FACILITY_CODE,
  ];
  public readonly emptyChildrenQuery: TCriteriaQuery = {
    type: CriteriaTypeEnum.AND,
    children: [
      {
        type: CriteriaTypeEnum.OR,
        children: [],
      },
      {
        type: CriteriaTypeEnum.NOT,
        children: [],
      },
    ],
  };
  public cleanedOverallSegment: TAudienceCriteria = {
    version: '1', query: {
      type: CriteriaTypeEnum.AND,
      children: [],
    },
  };
  public readonly enumeratedTemplateMapper: Map<string, string[]> = new Map([
    [TemplateKeys.PHYSICIAN_REFERRAL, [
      CriteriaIdEnum.PROVIDER_REFERRAL_PROVIDER_NAME,
      CriteriaIdEnum.PROVIDER_REFERRAL_DISPOSITION,
      CriteriaIdEnum.PROVIDER_REFERRAL_OUTCOME,
    ]],
    [TemplateKeys.SERVICE_REFERRAL, [
      CriteriaIdEnum.SERVICE_REFERRAL_SERVICE_DESC,
      CriteriaIdEnum.SERVICE_REFERRAL_DISPOSITION,
      CriteriaIdEnum.SERVICE_REFERRAL_OUTCOME,
    ]],
    [TemplateKeys.EVENT_REGISTRATION, [
      CriteriaIdEnum.EVENT_REGISTRATION_DESC,
      CriteriaIdEnum.EVENT_REGISTRATION_OUTCOME,
    ]],
    [TemplateKeys.PROVIDER, [
      CriteriaIdEnum.PROVIDER_NPI,
      CriteriaIdEnum.PROVIDER_ROLE_CODE,
      CriteriaIdEnum.PROVIDER_SPECIALTY_CODE,
    ]],
    [ TemplateKeys.EVENT_HRA, [
      CriteriaIdEnum.EVENT_HRA_NAME,
      CriteriaIdEnum.EVENT_HRA_RESULT,
    ]],
    [TemplateKeys.CUSTOM_ACTIVITY, [
      CriteriaIdEnum.CUSTOM_ACTIVITY_NAME,
      CriteriaIdEnum.CUSTOM_ACTIVITY_PROPERTY_1,
      CriteriaIdEnum.CUSTOM_ACTIVITY_PROPERTY_2,
      CriteriaIdEnum.CUSTOM_ACTIVITY_PROPERTY_3,
      CriteriaIdEnum.CUSTOM_ACTIVITY_PROPERTY_4,
      CriteriaIdEnum.CUSTOM_ACTIVITY_PROPERTY_5,
    ]],
    [TemplateKeys.HG_CUST_SERVICE_CATEGORY, [
      CriteriaIdEnum.SERVICE_CATEGORY,
      CriteriaIdEnum.SERVICE_LINE,
    ]],
    [TemplateKeys.HG_CUST_SERVICE_SUB_CATEGORY, [
      CriteriaIdEnum.SERVICE_SUB_CATEGORY,
      CriteriaIdEnum.SUB_SERVICE_LINE,
    ]],
    [TemplateKeys.FACILITY_HIERARCHY, [
      CriteriaIdEnum.FACILITY_GROUP_LEVEL_1,
      CriteriaIdEnum.FACILITY_GROUP_LEVEL_2,
      CriteriaIdEnum.FACILITY_GROUP_LEVEL_3,
    ]],
  ]);
  public criteriaDescription :BehaviorSubject<Record<string, any>> = new  BehaviorSubject<Record<string, any>>({});
  public segmentJson: BehaviorSubject<TCriteriaQuery | null> = new BehaviorSubject<TCriteriaQuery | null>(null);
  public isInvalidAudience: boolean = false;
  public invalidAudienceList: TInvalidAudienceResponse[] = [];
  public invalidCriterias:TInvalidCriteriaList = {
    audienceGroups : [],
    overallAudience: [],
    isValidAudience : false,
  }; 
  public invalidCriteriasWithGroupedByType: Record<string, TInvalidCriteriasGroupedModel> = {};
  /**
   * This method is used for building default Exclusion section
   * @memberof SegmentQueryService
   */
  public buildSegmentJsonWithDefaultExclusions() {
    return {
      type: 'and',
      children: [{
        type: 'not',
        children: [{
          type: 'or',
          children: [{
            type: 'and',
            children: [{
              value: true,
              type: CriteriaIdEnum.IS_DECEASED,
            }],
          }, {
            type: 'and',
            children: [{
              time_type: 'years',
              time: {
                min: 0,
                max: 17,
              },
              type: CriteriaIdEnum.BIRTH_DATE,
            }],
          }],
        }],
      }],
    };
  }

  public getSegmentJson(segment: any):TSegmentModel {
    return {
      criteria: {
        version: '1',
        query: segment,
      },
    };
  }

  public getDefaultLkpCriteria(): TCriteriaChildren {
    return {
      type: CriteriaTypeEnum.OR,
      children: [
        {
          children: [
            {
              type: CriteriaIdEnum.CHANNEL_AVAILABILITY,
              selected: this.defaultChannelAvailabilityForLkp,
            },
          ],
          type: CriteriaTypeEnum.AND,
        },
      ],
    };
  }

  public isTypeHasDesc(type: string): boolean {
    return this.descTypesCriteria.indexOf(type) > -1;
  }

  /**
   * getRiskLevel method is to get the risk level based on the category codes
   * @param categoryCodes category codes for which the risk level needs to be calculated
   * @returns selected risk level
   */
  public getRiskLevel(categoryCodes: number[]): string {
    const riskLeveLMapping = {
      Low: [1],
      Moderate: [2, 3],
      High: [4, 5, 6, 7],
    };
    const categoryDesc: string[] = [];
    let selectedRiskLevel = '';
    if (categoryCodes?.length) {
      // getting the first and last element from the categories if more than two categories are selected
      const categoryCodeRange = categoryCodes.length > 1 ? [categoryCodes[0], categoryCodes[categoryCodes.length - 1]] : [...categoryCodes];
      categoryCodeRange.forEach((category) => {
        for (const [key, values] of Object.entries(riskLeveLMapping)) {
          if (values?.includes(category) && !categoryDesc.includes(key)) {
            categoryDesc.push(key);
          }
        }
      });
      const riskLevelSuffix = ' Risk Level (Category Code' + (categoryCodes.length > 1 ? 's ' + categoryCodes[0] + '-' + categoryCodes[categoryCodes.length - 1] + '); ' : ' ' + categoryCodes[0] + '); ');
      const riskLevelPrefix = (categoryDesc.length === 2) ? (categoryDesc[0] + ' to ' + categoryDesc[1]) : categoryDesc[0];
      selectedRiskLevel = riskLevelPrefix + riskLevelSuffix;
    }
    return selectedRiskLevel;
  }

  /**
   * doesTypeExist method is to check if the type exists in the query
   * @param query query to check
   * @param type type to check
   * @returns boolean based on whether the type exists or not
   */
  public doesTypeExist(query: Record<string, any>[], type: CriteriaTypeEnum): boolean {
    const getNotItem = query?.filter((item) => item.type === type);
    return !!getNotItem.length;
  }

  /**
   * configureQuery method is to configure the query with default values
   * @param criteria criteria for which default values need to be configured
   */
  public configureQuery(criteria: TAudienceCriteria): TAudienceCriteria {
    if (criteria?.query?.children) {
      if (!this.doesTypeExist(criteria.query.children, CriteriaTypeEnum.OR)) criteria.query.children.push({
        type: CriteriaTypeEnum.OR,
        children: [],
      });
      if (!this.doesTypeExist(criteria.query.children, CriteriaTypeEnum.NOT)) criteria.query.children.push({
        type: CriteriaTypeEnum.NOT,
        children: [],
      });
      if (!this.doesTypeExist(criteria.query.children, CriteriaTypeEnum.AND)) criteria.query.children.push({
        type: CriteriaTypeEnum.AND,
        children: [],
      });
    } else criteria.query = structuredClone(this.emptyChildrenQuery);
    return criteria;
  }

  hasChildrenCriteria(segment: Record<string, any>): boolean {
    if (segment.children?.length === 0) return false;
    const hasInclusion = this.isOrEmpty(segment.children[0]?.children);
    const hasExclusion = segment.children.length > 1 && this.isOrEmpty(segment.children[1]?.children?.[0]?.children);
    return hasInclusion || hasExclusion;
  }

  private isOrEmpty(orChildren: Record<string, any>[] = []): boolean {
    return orChildren.some(child => (child?.children?.length ?? 0) > 0);  
  }

  public cleanCriteria(nodes: TCriteriaQuery): TCriteriaChildren {
    const cleanedCriteriaChildren = this.removeEmptyChildrenNodes(nodes);
    if (!cleanedCriteriaChildren) {
      return this.configureQuery({ version: '1', query: { type: CriteriaTypeEnum.AND, children: [] } }).query;
    }
    return cleanedCriteriaChildren;
  }

  public removeEmptyChildrenNodes(node: TCriteriaChildren): TCriteriaChildren | null {
    delete node.isOpen;
    if (node?.children?.length) {
      node.children = node.children
        .map(child => this.removeEmptyChildrenNodes(child))
        .filter(child => child !== null);
      if (node.children.length === 0) {
        return null;
      }
    } else {
      if (node?.children?.length === 0) return null;
      if (node?.selected?.length || node?.max !== undefined || node?.min !== undefined || 
        node?.time || node?.value || node?.value == false || 
        node?.codes?.length || (node?.points && node.points.length > 0) || 
        node?.drive_info || node?.selected_facility_groups || node?.selected_facility_codes
      ) {
        delete node.uuid;
        return node;
      }
      return null;
    }
    return node;
  }

  
  public formatInvalidResponse():void {
    if (this.invalidCriterias) {
      let audienceGroupArr = this.invalidCriterias.audienceGroups.flatMap(item => item.criteria);
      const groupByType = lodash.groupBy([...audienceGroupArr, ...this.invalidCriterias.overallAudience], 'type');
      this.invalidCriteriasWithGroupedByType = Object.fromEntries(
        Object.entries(groupByType).map(([criteriaName, criteriaGroup]) => [
          criteriaName,
          criteriaGroup.reduce(
            (previous, current) => lodash.mergeWith(previous, current, this.prepareUniqueSelectedInvalidItems),
            {},
          ),
        ]),
      );
    }
  }               

  prepareUniqueSelectedInvalidItems(target: any, source: any): any {
    if (lodash.isArray(target) && lodash.isArray(source)) {
      return Array.from(new Set(target.concat(source)));
    }
    return;
  }

  public validateCriteriaCard(filterDef:any):boolean {
    const invalidCriterias = this.invalidCriteriasWithGroupedByType;
    const invalidCriteria = invalidCriterias[filterDef.type]?.invalidCriteria;
    if (invalidCriteria) {
      return true;
    } else {
      switch (filterDef.type) {
        case CriteriaIdEnum.FACILITY_HIERARCHY:
          return this.validateFacilityCodes(filterDef, invalidCriterias[filterDef.type]);
        case CriteriaIdEnum.HOUSEHOLD_INCOME_RANGE_EXACT:
          return this.validateHouseHold(filterDef, invalidCriterias[filterDef.type]);
        default:
          return lodash.intersection(filterDef.selected, invalidCriterias[filterDef.type]?.invalidValues || []).length > 0;
      }
    }
  }

  private validateHouseHold(criteria: any, invalidValues: TInvalidCriteriasGroupedModel): boolean {
    return criteria.selected.some((item: any) => !invalidValues.invalidObj.some((values: any) => lodash.isEqual(item, values)));
  }

  private validateFacilityCodes(filterDef:any, invalidValues: TInvalidCriteriasGroupedModel): boolean {
    if (filterDef?.selected_facility_groups?.length) {
      const selectedGroupValues = lodash.flatten(filterDef.selected_facility_groups.map((item: any) => {
        // getting values and removing null
        return lodash.compact(lodash.values(item));
      }));
      const matchInvalidGroup = lodash.intersection(selectedGroupValues, invalidValues.invalidObj.selected_facility_groups);
      if (matchInvalidGroup.length > 0) {
        return true;
      }
    }

    if (filterDef?.selected_facility_codes?.length) {
      const matchInvalidCodes = lodash.intersection(filterDef.selected_facility_codes, invalidValues.invalidObj.selected_facility_codes);
      if (matchInvalidCodes.length > 0) {
        return true;
      }
    }
    return false;
  }

  validateCombinedCriteriaCard(combinedFilterDef: any): boolean {
    return combinedFilterDef.some((criteria: any) => this.validateCriteriaCard(criteria));
  }


  public validateCriteriaForInvalidAudience(segment: TCriteriaQuery):void {
    if (!segment || !segment.children) {
      return;
    }
    for (let i = segment.children.length - 1; i >= 0; i--) {
      const child: TCriteriaChildren = segment.children[i];
      const isSegmentType = [CriteriaTypeEnum.NOT, CriteriaTypeEnum.OR, CriteriaTypeEnum.AND, CriteriaTypeEnum.COMBINED].includes(child.type as CriteriaTypeEnum);
      if (!isSegmentType) {
        if (this.isInvalidAudience) {
          this.removeErrorFromCriteria(child);
        }
      }
      this.validateCriteriaForInvalidAudience(child);
    }
  }


  private removeErrorFromCriteria(criteria:any):void {
    const errorCriteria = this.invalidCriteriasWithGroupedByType[criteria.type];
    if (errorCriteria) {
      if (errorCriteria.invalidCriteria) {
        delete criteria.selected;
        delete criteria.value;
        delete criteria.codes;
        delete criteria.time_type;
        delete criteria.min;
      } else if (errorCriteria.invalidObj) {
        switch (criteria.type) {
          case CriteriaIdEnum.FACILITY_HIERARCHY:
            this.facilityValueValidator(criteria, errorCriteria);
            break;
          case CriteriaIdEnum.HOUSEHOLD_INCOME_RANGE_EXACT:
            this.houseHoldIncome(criteria, errorCriteria);
            break;
        }
      } else if ( errorCriteria?.invalidValues?.length > 0 && criteria.selected) {
        criteria.selected = criteria.selected.filter((value: any) => !errorCriteria.invalidValues.includes(value));
      }
    }
  }

  private facilityValueValidator(criteria: any, errorCriteria: TInvalidCriteriasGroupedModel | undefined):void {
    if (criteria?.selected_facility_codes?.length) {
      criteria.selected_facility_codes = lodash.difference(criteria.selected_facility_codes, errorCriteria?.invalidObj.selected_facility_codes);
      if (criteria.selected_facility_codes.length < 1) {
        delete criteria.selected_facility_codes;
      }
    }
    if (criteria?.selected_facility_groups?.length) {
      criteria.selected_facility_groups = criteria.selected_facility_groups.filter((item: any) => {
        const itemValues = Object.values(item);
        if (errorCriteria?.invalidObj?.selected_facility_groups &&
        itemValues.some(value => errorCriteria.invalidObj.selected_facility_groups.includes(value))) {
          return false; // If hierarchy doesn't exist, remove item
        }
        return lodash.compact(itemValues).length > 0;
      });
    }
  }

  private houseHoldIncome(criteria:any, errorCriteria:TInvalidCriteriasGroupedModel | undefined):void {
    criteria.selected = criteria.selected.filter((range:any) => {
      return !errorCriteria?.invalidObj.some((invalid:any) => lodash.isEqual(range, invalid));
    });
  }

}
