// Core Modules
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

// External Modules
import { CacheFactory } from 'cachefactory';
import Highcharts from 'highcharts';
import { NgxPermissionsModule } from 'ngx-permissions';
import { provideUserIdleConfig } from 'angular-user-idle';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { PemSharedModule } from './pem-shared/pem-shared.module';

// Components
import { AppComponent } from './app.component';
import { StyleGuideComponent } from './resources/style-guide/style-guide.component';

// Environment
import { environment } from '../environments/environment';
/*
 * Application common modules.
 * This array holds all common modules used across the application.
 * TODO: iot will be a good idea to refactor into a library.
 * --------------------------------------------------------------------------------------------------------
 * UserIdleModule for example 10 mins is idle stage and Timeout period is 3 mins
 * [-----Idle Time--]
 * [1,2,....10 mins]-> [1,2,...3 mins]  -> Timeout
 *                  -> Idle Time starts -> 13 mins 1 seconds onwards
 * If we want to perform any operation periodically we can use ping method for refresh token, check session
 * idle : No of seconds - Indicates Idle stage,
 * timeout: No of seconds - Timeout period
 * ping : No of seconds - we can perform refresh api call invocation periodically.
 * ---------------------------------------------------------------------------------------------------------
 */
/**
 * /**
 * PEM_MODULES is used to import core module and pem shared module
 */
const PEM_MODULES = [
  CoreModule,
  PemSharedModule,
];

Highcharts.setOptions({
  exporting: {
    enabled: false,
    showTable: true,
  },
  lang: {
    thousandsSep: ',',
  },
  chart: {
    style: {
      fontFamily: 'plusjakartasans',
    },
  },
  legend: {
    events: {
      itemClick: () => false,
    },
  },
});

@NgModule({
  declarations: [
    AppComponent,
    StyleGuideComponent,
  ],
  imports: [
    BrowserModule,
    NgxPermissionsModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    PEM_MODULES,
  ],
  providers: [CacheFactory, { provide: 'Window', useValue: window }, provideUserIdleConfig({ idle: environment.SESSION.maxIdleWaitTime, timeout: environment.SESSION.logoutNotifyExpiryTime, ping: environment.SESSION.refreshSessionInterval })],
  bootstrap: [AppComponent],
})
export class AppModule { }