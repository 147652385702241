export enum AudienceActions {
  AUDIENCE_SETTINGS = 'Audience Settings',
  CRITERIA_SUMMARY = 'Criteria Summary',
  DELETE_CRITERIA_GROUP = 'Delete Criteria Group',
  DOWNLOAD_REPORTS = 'Download Reports',
  DUPLICATE_CRITERIA_GROUP = 'Duplicate Criteria Group',
  PROMOTE_TO_TEMPLATE = 'Promote to Template',
  SAVE_AUDIENCE = 'Save Audience',
  SAVE_TEMPLATE = 'Save Template',
  SHARE = 'Share',
  BACK_BUTTON = 'backBtn',
  TEMPLATE_SETTINGS = 'Template Settings',
  VIEW_ALL_DEMOGRAPHIC_REPORTS = 'View All Demographic Reports',
}

export enum AudienceButtonEnum {
  CREATE_AUDIENCE = 'Create Audience',
  SMART_TEMPLATE = 'Smart Template',
  TEMPLATE_LIST = 'Template List',
}

export enum AudienceDropDownItemEnum {
  DELETE_AUDIENCE = 'Delete Audience',
  DUPLICATE_AUDIENCE = 'Duplicate Audience',
}

export enum AudienceSuppressableForEnum {
  INTERNAL = 'INTERNAL',
  LKP = 'LKP',
}

export enum AudienceTypeEnum {
  CMP = 'CMP',
  LKP = 'LKP',
  MIXED = 'MIXED',
}

export enum ApproverListButtonEnum {
  APPROVE = 'Approve',
  REJECT = 'Reject',
  PREVIEW = 'Preview',
}

export enum ChannelAvailabilitySelectOptionsEnum {
  EMAIL = 'email',
  PHONE = 'phone',
}

export enum CriteriaActionEnum {
  DELETE_CRITERIA_GROUP = 'Delete Criteria Group',
  DUPLICATE_CRITERIA_GROUP = 'Duplicate Criteria Group',
}
export enum CriteriaFilterTypeEnum {
  CUSTOM_SERVICE_LINE = 'custom_service_line',
  MOVE_DATE = 'move-date',
  ENUMERATED_TEMPLATE = 'enumerated-template',
  TYPEAHEAD_SEARCH = 'typeahead-search',
  SINGLE_TEMPLATE = 'single-template',
  MULTIPLE_TEMPLATE = 'multiple-template',
  CHILDREN_COUNT = 'children-count',
}

export enum CriteriaIdEnum {
  ADMIT_DATE = 'admit_date',
  ADMIT_SOURCE = 'admit_source',
  ADMIT_TYPE = 'admit_type',
  AVAILABLE_CHANNEL = 'available_channel',
  BIRTH_DATE = 'birth_date',
  BIRTH_MONTH = 'birth_month',
  CALL_DISPOSITION = 'call_disposition',
  CALL_TYPE = 'call_type',
  CHANNEL_AVAILABILITY = 'available_channel',
  CHILD_COUNT = 'child_count',
  CHUI = 'chui',
  CONTENT_DESC = 'content_desc',
  CPT_CODE = 'cpt_code',
  CUSTOM_ACTIVITY = 'custom_activity',
  CUSTOM_ACTIVITY_NAME = 'custom_activity_name',
  CUSTOM_ACTIVITY_PROPERTY = 'custom_activity_properties',
  CUSTOM_ACTIVITY_PROPERTY_1 = 'custom_activity_property_1',
  CUSTOM_ACTIVITY_PROPERTY_2 = 'custom_activity_property_2',
  CUSTOM_ACTIVITY_PROPERTY_3 = 'custom_activity_property_3',
  CUSTOM_ACTIVITY_PROPERTY_4 = 'custom_activity_property_4',
  DIAGNOSIS_CODE = 'diagnosis_code',
  DISCHARGE_DATE = 'discharge_date',
  DISCHARGE_DISPOSITION = 'discharge_disposition',
  EDUCATION_LEVEL = 'education_level',
  ENCOUNTER_PAYOR_CATEGORY = 'encounter_payor_category',
  ENCOUNTER_TYPE = 'encounter_type',
  EVENT_CALL_LIST = 'event_call_list',
  EVENT_CAMPAIGN = 'event_campaign',
  EVENT_CHANNEL = 'event_channel',
  EVENT_DATE = 'event_date',
  EVENT_DIRECT_MAIL_LIST = 'event_direct_mail_list',
  EVENT_EMAIL_NAME = 'event_email_name',
  EVENT_FORM_NAME = 'event_form_name',
  EVENT_HRA = 'event_hra',
  EVENT_HRA_LOCATION = 'event_hra_location',
  EVENT_REGISTRATION = 'event_registration',
  EVENT_REGISTRATION_DESC = 'event_registration_desc',
  EVENT_TYPE = 'event_type',
  EXTERNAL_LIST = 'external_list',
  FACILITY_CODE = 'facility_code',
  FINANCIAL_CLASS = 'financial_class',
  GENDER = 'gender',
  HG_CUST_SERVICE_CATEGORY = 'hg_cust_service_category',
  HG_CUST_SERVICE_SUB_CATEGORY = 'hg_cust_service_sub_category',
  HOME_OWNER = 'home_owner',
  HOUSEHOLD_AGE_BANDS = 'household_age_bands',
  HOUSEHOLD_INCOME_RANGE_EXACT = 'household_income_range_exact',
  HOUSEHOLD_INTERESTS = 'household_interests',
  IS_DECEASED = 'is_deceased',
  IS_HEAD_OF_HOUSEHOLD = 'is_head_of_household',
  LANGUAGE = 'language',
  MARITAL_STATUS = 'marital_status',
  MARKET_AREA = 'market_area',
  MSDRG_CODE = 'msdrg_code',
  MOVE_DATE = 'move_date',
  MOVE_DISTANCE = 'move_distance',
  MOVE_FROM_MARKET_AREA = 'move_from_market_area',
  MOVE_FROM_POSTAL_CODE = 'move_from_postal_code',
  MOVE_TO_MARKET_AREA = 'move_to_market_area',
  MOVE_TO_POSTAL_CODE = 'move_to_postal_code',
  NET_WORTH = 'net_worth',
  NICHE_CODE = 'niche_code',
  OCCUPATION = 'occupation',
  PATIENT_PAYOR_NAME = 'patient_payor_name',
  PDI = 'pdi',
  PERCEPTUAL_PROFILE_CODE = 'perceptual_profile_code',
  PERSON_CUSTOM_ATTRIBUTE_NUMBER = 'person_custom_attribute_number',
  PERSON_PAYOR_CATEGORY = 'person_payor_category',
  PHYSICIAN_REFERRAL = 'physician_referral',
  POSTAL_CODE = 'postal_code',
  PROCEDURE_CODE = 'procedure_code',
  PROVIDER = 'provider',
  PROVIDER_NPI = 'provider_npi',
  PROVIDER_REFERRAL_DISPOSITION = 'provider_referral_disposition',
  PROVIDER_REFERRAL_PROVIDER_NAME = 'provider_referral_provider_name',
  PROVIDER_ROLE_CODE = 'provider_role_code',
  PROVIDER_SPECIALTY_CODE = 'provider_specialty_code',
  RACE = 'race',
  RECENCY_FREQUENCY_CODE = 'recency_frequency_code',
  SERVICE_CATEGORY = 'service_category',
  SERVICE_LINE = 'service_line',
  SERVICE_REFERRAL = 'service_referral',
  SERVICE_REFERRAL_DISPOSITION = 'service_referral_disposition',
  SERVICE_REFERRAL_SERVICE_DESC = 'service_referral_service_desc',
  SERVICE_SUB_CATEGORY = 'service_sub_category',
  SUB_SERVICE_LINE = 'sub_service_line',
  SUB_FACILITY_CODE = 'sub_facility_code',
  PATIENT_TYPE = 'patient_type',
  POSTAL_MOVED_TO = 'postal_moved_to',
  POSTAL_MOVED_FROM = 'postal_moved_from',
  PROVIDER_REFERRAL_OUTCOME = 'provider_referral_outcome',
  SERVICE_REFERRAL_OUTCOME = 'service_referral_outcome',
  EVENT_REGISTRATION_OUTCOME = 'event_registration_outcome',
  ACTIVITY_DIVISION_CODE = 'activity_division_code',
  ENCOUNTER_DIVISION_CODE = 'encounter_division_code',
  EVENT_HRA_NAME = 'event_hra_name',
  EVENT_HRA_RESULT = 'event_hra_result',
  CUSTOM_ACTIVITY_PROPERTY_5 = 'custom_activity_property_5',
  FACILITY_GROUP_LEVEL_1 = 'facility_group_level_1',
  FACILITY_GROUP_LEVEL_2 = 'facility_group_level_2',
  FACILITY_GROUP_LEVEL_3 = 'facility_group_level_3',
  DONATION_DATE = 'donation_date',
  FACILITY_HIERARCHY = 'facility_hierarchy',
  PERSON_CUSTOM_ATTRIBUTE_STRING = 'person_custom_attribute_string',
  ALL_PROCEDURE_CODE = 'all_procedure_code',
  PERSON_CUSTOM_ATTRIBUTE_DATE = 'person_custom_attribute_date',
  PERSON_CUSTOM_ATTRIBUTE_BOOLEAN = 'person_custom_attribute_boolean',
}

export enum TemplateKeys {
  PHYSICIAN_REFERRAL = 'physician_referral',
  SERVICE_REFERRAL = 'service_referral',
  EVENT_REGISTRATION = 'event_registration',
  PROVIDER = 'provider',
  EVENT_HRA = 'event_hra',
  CUSTOM_ACTIVITY = 'custom_activity',
  HG_CUST_SERVICE_CATEGORY = 'hg_cust_service_category',
  HG_CUST_SERVICE_SUB_CATEGORY = 'hg_cust_service_sub_category',
  FACILITY_HIERARCHY = 'facility_hierarchy',
}

export enum CriteriaTypeEnum {
  NOT = 'not',
  OR = 'or',
  AND = 'and',
  COMBINED = 'combined',
}

export enum TemplateGroupEnum {
  GLOBAL = 'GLOBAL',
  INTERNAL = 'INTERNAL',
}

export enum TemplateStatusEnum {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum ActivityTypeEnum {
  CALL = 'call',
  CALL_CONTENTREQUEST = 'call_contentrequest',
  CALL_EVENTREGISTRATION = 'call_eventregistration',
  CALL_PROVIDERREFERRAL = 'call_providerreferral',
  CALL_SERVICEREFERRAL = 'call_servicereferral',
  CUSTOM_ACTIVITY = 'custom_activity',
  DIRECTMAIL_SEND = 'directmail_send',
  EMAIL_CLICK = 'email_click',
  EMAIL_OPEN = 'email_open',
  EMAIL_SEND = 'email_send',
  FORM_SUBMIT = 'form_submit',
  HRA_SUBMIT = 'hra_submit',
  WEB_VISIT = 'web_visit',
}

export enum SegmentMetricsChartTypeEnum {
  CHART_TYPE = 'highchart',
  PAYOR_CATEGORY_MIX = 'payor-category-mix',
  CHANNEL_AVAILABILITY = 'channel-availability',
  HOUSEHOLD_INCOME = 'household-income',
  AGE_GENDER_BREAKDOWN = 'age-gender-breakdown',
}

export enum AudienceEvents {
  TYPE_CHANGE = 'type-change',
  TAB_CHANGE = 'tab-change',
  AUDIENCE_GROUP_CHANGE = 'audience-group-change',
  ADD_DEFAULT_GROUPS = 'add-default-groups',
  GROUP_CRITERIA_UPDATED = 'group-criteria-update',
  AUDIENCE_GROUP_FIRST_DRAG = 'audience-group-first-drag',
  DELETE_AUDIENCE = 'delete-audience',
  SHOW_NA_MODAL = 'show-na-modal',
  LOAD_NA_DATA = 'load-na-data',
  ZERO_AUDIENCE_GROUPS = 'all-audience-groups-deleted',
  DELETE_CRITERIA = 'delete-criteria',
}

export enum AudienceModalTypeEnum {
  INVALID_AUDIENCE_MODAL = 'invalid_audience_modal',
  NA_AUDIENCE_MODAL = 'na_audience_modal',
}