import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { EditAudienceService } from '../../edit-audience/services/edit-audience.service';

export const UnsavedAudienceDetectorGuard : CanActivateFn = (event: ActivatedRouteSnapshot) => {
  const editAudienceService = inject(EditAudienceService); 
  if (!editAudienceService.isCriteriaUnsaved) return true;
  editAudienceService.openUnsavedAudienceModal(event.routeConfig?.path as string).catch(() => {
    console.log('Unsaved audience modal cancelled');
  });
  return !editAudienceService.isCriteriaUnsaved;
};